export default [
	// {
	// 	title: '2023 Holiday Lookbook',
	// 	description: '',
	// 	image: require('../../../images/lookbooks/holiday-2023.png'),
	// 	flippingBookId: '280114399',
	// 	slug: '2023-holiday-lookbook',
	// },
	{
		title: 'Swagger Services',
		description: 'Quis nostrud exercitation ullamco laboris nisi, aute irure dolor in reprehenderit.',
		image: require('../../../images/lookbooks/swagger-services.png'),
		flippingBookId: '730184330',
		slug: 'swagger-services-lookbook',
	},
	{
		title: 'Summer 2024',
		description: '',
		image: require('../../../images/lookbooks/summer-2024.png'),
		flippingBookId: '559746050',
		slug: 'summer-2024',
	},
	{
		title: 'Brands We Love',
		description: '',
		image: require('../../../images/lookbooks/brands-we-love.png'),
		flippingBookId: '380994899',
		slug: 'brands-we-love',
	},
	{
		title: '2024 Trends',
		description: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
		image: require('../../../images/lookbooks/2024-trends.png'),
		flippingBookId: '484971166',
		slug: '2024-trends',
	},
	{
		title: 'Eco-Friendly Conference Swag',
		description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
		image: require('../../../images/lookbooks/conference-swag.png'),
		flippingBookId: '252648042',
		slug: 'eco-friendly-conference-swag',
	},
	{
		title: 'New Hire Kits',
		description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.',
		image: require('../../../images/lookbooks/new-hires-kit-lookbook.png'),
		flippingBookId: '278900670',
		slug: 'new-hire-kits',
	},
	{
		title: 'Kits & Gifts',
		description: '',
		image: require('../../../images/lookbooks/kits-and-gifts.png'),
		flippingBookId: '1070243853',
		slug: 'kits-and-gifts',
	},
	{
		title: '2023 Trends',
		description: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
		image: require('../../../images/lookbooks/2023-trends.png'),
		flippingBookId: '566946303',
		slug: '2023-trends',
	},
	// {
	// 	title: '2022 Holiday Lookbook',
	// 	description: 'Lorem ipsum dolor sit amet, reprehenderit in voluptate velit esse, quis nostrud exercitation.',
	// 	image: require('../../../images/lookbooks/holiday-lookbook-2022.jpg'),
	// 	flippingBookId: '984060690',
	// 	slug: '2022-holiday-lookbook',
	// },
	// {
	// 	title: '',
	// 	description: '',
	// 	image: require('../../../images/'),
	// 	flippingBookId: '',
	// 	slug: '',
	// },
]