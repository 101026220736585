export default [
	{
		title: "Webflow",
		alt: "Custom branded MiiR wine bottle set laser etched for Webflow and Napa Maptote Bag sourced and decorated by Swagger.",
		image: require('../../../images/tgs/webflow.png'),
		noModal: true
	},
	{
		title: "Levi Strauss & Co",
		alt: "Tumbler and mug set printed with custom branded logo for Levi's Women's DEI team by Swagger.",
		image: require('../../../images/tgs/levis.png'),
		noModal: true
	},
	{
		title: "Brighthire",
		alt: "Custom branded t-shirt and matching custom notebook with for Brighthire AZ team offsite by Swagger.",
		image: require('../../../images/tgs/brighthire.png'),
		noModal: true
	},
	{
		title: "Seeq",
		alt: "Igloo custom branded cooler bag, Austin candle, branded Nimble charger, etched tumbler produced by Swagger.",
		image: require('../../../images/tgs/seeq.png'),
		noModal: true
	},
	{
		title: "Tenable",
		alt: "Custom branded Beats earphones decorated by Swagger.",
		image: require('../../../images/tgs/tenable.png'),
		noModal: true
	},
	{
		title: "Gem",
		alt: "Custom branded OHOM mug, tone-on-tone black printed logo, copper cow coffee set and mast chocolate bar curated by Swagger.",
		image: require('../../../images/tgs/gem.png'),
		noModal: true
	},
	{
		title: "Spotlyfe",
		alt: "Spotlyfe new hire welcome kit filled with custom branded apparel: tumbler, custom socks, beanie with tag, custom designed notebook, custom mousepad curated and kitted by Swagger.",
		image: require('../../../images/tgs/spotlyfe.jpg'),
		noModal: true
	},
	{
		title: "Tri-Valley Ventures",
		alt: "Apololis market tote bag printed with custom logo created by Swagger.",
		image: require('../../../images/tgs/trivalleyventures.png'),
		noModal: true
	},
	{
		title: "Teamwork",
		alt: "The North Face puffer jacket and beanie with matching custom patches designed by Swagger.",
		image: require('../../../images/tgs/teamwork.png'),
		noModal: true
	},
	{
		title: "Thankful",
		alt: "Thankful Tag screen printed t-shirt with custom sleeve tag designed and produced by Swagger.",
		image: require('../../../images/tgs/thankful.jpg'),
		noModal: true
	},
	{
		title: "The New Happy",
		alt: "The New Happy and LinkedIn collaboration custom branded notebooks and custom branded S'well traveler water bottle created by Swagger.",
		image: require('../../../images/tgs/thenewhappy.png'),
		noModal: true
	},
	{
		title: "Storied",
		alt: "Custom branded Herschel Backpack with Storied logo decorated by Swagger.",
		image: require('../../../images/tgs/storied.jpg'),
		noModal: true
	},
	{
		title: "Chronicle Books",
		alt: "Custom branded Fellow Carter Tumbler for Chronicle Books produced by Swagger.",
		image: require('../../../images/tgs/chroniclebooks.png'),
		noModal: true
	},
	{
		title: "Time Payment",
		alt: "Custom branded socks with TimePayment company clock design in green produced by Swagger.",
		image: require('../../../images/tgs/timepayment.png'),
		noModal: true
	},
	{
		title: "Intuit",
		alt: "Remote meeting box for Intuit including custom branded bkr water bottle, custom branded Moleskine, custom branded s-gel sharpie pen curated, kitted and shipped by Swagger.",
		image: require('../../../images/tgs/intuit.png'),
		noModal: true
	},
	{
		title: "Asana",
		alt: "Custom branded diffuser created and shipped globally for Asana by Swagger.",
		image: require('../../../images/tgs/asana.png'),
		noModal: true
	},
	{
		title: "Google",
		alt: "Sherpa blanket with embroidered custom branded Super G logo for Google created by Swagger.",
		image: require('../../../images/tgs/google.png'),
		noModal: true
	},
	{
		title: "Dwight Clark Foundation",
		alt: "Custom branded trucker hat for San Francisco 49ers Dwight Clark Foundation decorated by Swagger.",
		image: require('../../../images/tgs/dwightclarkfoundation.png'),
		noModal: true
	},
	{
		title: "Peloton",
		alt: "Custom branded embroidered puffer vest produced, packaged and shipped globally for Peloton by Swagger.",
		image: require('../../../images/tgs/peloton.png'),
		noModal: true
	},
	{
		title: "Webflow",
		alt: "Custom branded sales kick off logo created for Webflow and used on several items including embroidered crewneck sweatshirt, printed logo on Elemental water bottle, printed logo on Nimble charger by Swagger.",
		image: require('../../../images/tgs/webflow2.jpg'),
		noModal: true
	},
	{
		title: "HCVT",
		alt: "Hoodie with custom embroidered logo on sleeve produced by Swagger.",
		image: require('../../../images/tgs/hcvt.png'),
		noModal: true
	},
	{
		title: "Newsbreak",
		alt: "Custom branded S’well Tumbler for Newsbreak by Swagger.",
		image: require('../../../images/tgs/newsbreak.jpg'),
		noModal: true
	},
	{
		title: "Contrary",
		alt: "Custom branded Marine Layer blanket with embroidered Contrary logo by Swagger.",
		image: require('../../../images/tgs/contrary.jpg'),
		noModal: true
	},
	{
		title: "Gem",
		alt: "Gem custom branded gift box with etched cocktail glasses and matching flask curated, produced and delivered by Swagger.",
		image: require('../../../images/tgs/gem2.png'),
		noModal: true
	},
	{
		title: "Armis",
		alt: "Timbuk2 custom branded backpack with embroidery featured in a pop-up shop created by Swagger.",
		image: require('../../../images/tgs/armis.png'),
		noModal: true
	},
	{
		title: "Talentful",
		alt: "Talentful new hire kit with custom printed hoodie, custom notebook, custom socks, custom pen, custom water bottle created and distributed by Swagger.",
		image: require('../../../images/tgs/talentful.png'),
		noModal: true
	},
	{
		title: "GO1",
		alt: "Custom branded MiiR camp mugs decorated and shipped by Swagger.",
		image: require('../../../images/tgs/go1.png'),
		noModal: true
	},
	{
		title: "Sephora",
		alt: "White Moleskine notebook with debossed Sephora logo curated and created by Swagger.",
		image: require('../../../images/tgs/sephora.jpg'),
		modalImage: require('../../../images/tgs/sephora.jpg'),
		content: [
			"Sephora is ready for their spring awakening! To invite employees back to work at the brand’s SF headquarters, we jumped on board to create a clean and completely cool white-on-white welcome back kit, stocked with this modern classic Moleskine notebook, each branded only with a whisper of Sephora.",
			"<b>IN A WORD:</b> Fresh."
		]
	},
	{
		title: "Amazon Fire TV",
		alt: "Tone-on-tone printed logo on MiiR travel tumblers for Fire TV created by Swagger.",
		image: require('../../../images/tgs/firetv.jpg'),
		modalImage: require('../../../images/tgs/modal-images/firetv.jpg'),
		content: [
			"This is not your average water bottle. The team at Amazon Fire TV wanted something special. And not too over-the-top on the branding. We found the perfect match in Amazon’s fellow Seattle-based brand, Miir. The end result is a high-quality, reusable water bottle in a matte-black finish and just a splash of sleek branding.",
			"<b>IN A WORD:</b> Fire."
		]
	},
	{
		title: "Accion Opportunity Fund",
		alt: "Custom branded AOF water bottles created by Swagger.",
		image: require('../../../images/tgs/accion.jpg'),
		modalImage: require('../../../images/tgs/accion.jpg'),
		content: [
			"The newly merged Accion Opportunity Fund is dedicated to revolutionizing financial services for underserved communities. Our mission was to help them create a daily reminder of this new union in an emotional, yet practical, way. We were inspired to set their colorful logo off against the crisp, clean white of a reusable water bottle.",
			"<b>IN A WORD:</b> Empowering."
		]
	},
	{
		title: "Fleetcor",
		alt: "Custom branded MiiR tumbler with red Fleetcor logo created, stored and shipped on-demand by Swagger.",
		image: require('../../../images/tgs/wifibottles.png'),
		noModal: true
	},
	{
		title: "Talentful",
		alt: "Custom Talentful t-shirt designed by Swagger to help Talentful celebrate their 100 employee milestone.",
		image: require('../../../images/tgs/100.png'),
		noModal: true
	},
	{
		title: "Untamed Planet",
		alt: "Rumpl custom branded blanket embroidered with Untamed Planet logo produced by Swagger.",
		image: require('../../../images/tgs/untamedplanet.jpg'),
		noModal: true
	},
	{
		title: "Currency",
		alt: "Custom branded Currency Cooler with embroidered logo curated and decorated by Swagger.",
		image: require('../../../images/tgs/currency.jpg'),
		modalImage: require('../../../images/tgs/currency.jpg'),
		content: [
			"Currency is a SoCal based FinTech company–and an amazing partner. This time around, we worked together to create a holiday gift for their employees. It had to vibe with their quarterly outings at the beach and be sustainable. The solve? These slick, reusable coolers in matte black, the perfect backdrop to make their logo really pop.",
			"<b>IN A WORD:</b> Cheers!"
		]
	},
	{
		title: "Byte",
		alt: "Custom branded Byte S'well Chillers created by Swagger.",
		image: require('../../../images/tgs/byte.jpg'),
		modalImage: require('../../../images/tgs/modal-images/byte.jpg'),
		content: [
			"It’s been an amazing year for Byte, a leader in the at-home teeth alignment category. To celebrate–and kick off summer with a perfect smile–we created a gift pack for each employee, including a portable speaker and a pair of chillers, all branded with Byte’s bitten “B” logo.",
			"<b>IN A WORD:</b> Party."
		]
	},
	{
		title: "Trove",
		alt: "Custom branded Herschel Backpack produced by Swagger.",
		image: require('../../../images/tgs/trove.jpg'),
		modalImage: require('../../../images/tgs/trove.jpg'),
		content: [
			"Trove transcends your typical subscription box. Each box focuses on a new city and delivers deliciously differenct ingredients, snacks, goodies–and live virtual experiences–from around the world, right to your doorstep. Trove-branded Herschel bags and beanies made great travel companions for their globe-trotting gourmands (aka employees).",
			"<b>IN A WORD:</b> Vamos!"
		]
	},
	{
		title: "AgDirect",
		alt: "Custom branded S'well Chillers for AgDirect created by Swagger.",
		image: require('../../../images/tgs/agdirect.jpg'),
		modalImage: require('../../../images/tgs/agdirect.jpg'),
		content: [
			"It’s gonna be a hot one, so AgDirect (providers of equipment loans for the ag industry) is showing their appreciation by creating a customized gift. These premium branded chillers from S’well keep cans cold and help their dealers survive another sweltering summer.",
			"<b>IN A WORD:</b> Cool."
		]
	},
	{
		title: "Sephora",
		alt: "Custom embroidered crewneck sweatshirt tone-on-tone all white for Sephora designed and created by Swagger.",
		image: require('../../../images/tgs/sephora2.jpg'),
		modalImage: require('../../../images/tgs/modal-images/sephora2.jpg'),
		content: [
			"To invite employees back to work at the brand’s SF headquarters, we jumped on board to create a clean and completely cool white-on-white welcome back kit, stocked with this super soft Alternative Apparel sweatshirt branded only with a whisper of Sephora.",
			"<b>IN A WORD:</b> Fresh."
		]
	},
	{
		title: "First Republic Bank",
		alt: "Custom logo embroidered Marine Layer blanket for First Republic created by Swagger.",
		image: require('../../../images/tgs/firstrepublic.jpg'),
		modalImage: require('../../../images/tgs/modal-images/firstrepublic.jpg'),
		content: [
			"Clients come first at First Republic Bank. And they really feel the love when they open their gift of an absurdly soft, premium embroidered blanket from Marine Layer, along with a pair of extra cool, Eagle-branded S’well chillers.",
			"<b>IN A WORD:</b> Luxe."
		]
	},
	{
		title: "Fisher Investments",
		alt: "Custom branded growler for Fisher Investments curated by Swagger.",
		image: require('../../../images/tgs/fisher.jpg'),
		modalImage: require('../../../images/tgs/fisher.jpg'),
		content: [
			"Fisher Investments wasn’t about to let quarantine prevent them from celebrating their employees’ hard work (and the company’s great quarter). The solution? Bring happy hour to their employees with this sleek FI-branded stainless growler and matching glasses.",
			"<b>IN A WORD:</b> Prost!"
		]
	},
	{
		title: "eBay Motors",
		alt: "Custom branded S'well Chillers for eBay motors created by Swagger.",
		image: require('../../../images/tgs/ebaymotors.jpg'),
		modalImage: require('../../../images/tgs/modal-images/ebaymotors.jpg'),
		content: [
			"New or used. Foreign or domestic. From cars and motorcycles to parts and accessories, eBay Motors has got you covered. And they know what’s up when it comes to impressing customers with these branded S’well chillers.",
			"<b>IN A WORD:</b> Surprise!"
		]
	},
	{
		title: "Currency",
		alt: "Stack of Currency custom branded embroidered zip up hoodies decorated by Swagger.",
		image: require('../../../images/tgs/currency2.jpg'),
		modalImage: require('../../../images/tgs/modal-images/currency2.jpg'),
		content: [
			"To keep everyone comfy and safe in quarantine over the holidays, we partnered with our favorite LA-based FinTech crew at Currency to surprise their employees with a high-quality hoodie (think built to last) and a reusable, Currency-branded mask.",
			"<b>IN A WORD:</b> Cozy."
		]
	}
]