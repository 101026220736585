export default [
	{
		title: "Brand We Love: Elemental",
		date: new Date("9/1/22"),
		slug: "elemental",
		description: "Spread the love with Elemental drinkware, a brand transforming eco-friendly culture into a lasting lifestyle. Discover why it’s one of Swagger’s top picks for custom swag.",
		image: require('../../../images/blog/elemental/elemental.webp'),
		content: '',
	},
	{
		title: "Eco-Friendly Packaging: Why It Matters to Your Brand",
		date: new Date("8/1/22"),
		slug: "eco-friendly-packaging",
		description: "Find out why eco-friendly packaging isn’t just good for the environment, but the benefits of how it creates brand affinity with eco-conscious consumers!",
		image: require('../../../images/blog/eco-friendly.png'),
		content: '',
	},
	{
		title: "Brand We Love: Allmade Apparel",
		date: new Date("7/1/22"),
		slug: "allmade-apparel",
		description: "Dive into Allmade’s eco-friendly apparel. Take a look at their custom swag and read more about how they give back to the planet.",
		image: require('../../../images/blog/allmade/allmade1.jpg'),
		content: '',
	},
	{
		title: "5 Simple Ways to Live More Sustainably",
		date: new Date("6/1/22"),
		slug: "sustainable-living",
		description: "Discover 5 simple ways to live more sustainably with easy lifestyle changes that will help reduce carbon footprint and help you reuse, reduce, and recycle.",
		image: require('../../../images/blog/live-more-sustainably.png'),
		content: '',
	},
	{
		title: "Brand We Love: Atlantis Headware",
		date: new Date("12/1/21"),
		slug: "sustainable-beanies",
		description: "Explore Atlantis Headware and how their deep commitment to the environment is changing the way they do custom branded swag. Read more about why Swagger thinks headware is the great equalizer.",
		image: require('../../../images/blog/atlantis/atlantis1.png'),
		content: "",
	},
	{
		title: "The Top 8 Swag Trends of 2022",
		date: new Date("1/1/22"),
		slug: "top-8-swag-trends-2022",
		description: "If you are looking for ways to improve your eco-friendly packaging, this guide can help. Here are the top eight swag trends to follow in 2022.",
		image: require('../../../images/blog/swag-trends.png'),
		content: '',
	},
	{
		title: "Wow! Factor: The Best Sustainable Conference Swag",
		date: new Date("4/1/22"),
		slug: "wow-factor-the-best-sustainable-conference-swag",
		description: "Discover the latest in eco-friendly and stylish custom swag for your next event. From sustainable picks to trendy designs, find your perfect conference swag.",
		image: require('../../../images/blog/conference/conference-swag.png'),
		content: '',
	},
	{
		title: "ONBOARD IN STYLE: New Hire Kits Get the Glow Up",
		date: new Date("11/1/21"),
		slug: "onboard-in-style-new-hire-kits-get-the-glow-up",
		description: "Jump into our new hire kit guide and elevate your custom swag. Read on to find out how to make your new hires feel a part of the team in a big way.",
		image: require('../../../images/blog/onboard/onboard-in-style.png'),
		content: "",
	},
	{
		title: "Step Up Your Holiday Gifts: Give Employees and Clients Sustainable Swag with Style!",
		date: new Date("10/1/21"),
		slug: "step-up-your-holiday-gifts-give-employees-and-clients-sustainable-swag-with-style",
		description: "Showing appreciation for your business partners, clients, and employees is good. Giving them sustainable holiday gifts is better. Read on.",
		image: require('../../../images/blog/holiday-swag.png'),
		content: '',
	},
	{
		title: "Brand We Love: Marine Layer",
		date: new Date("5/1/22"),
		slug: "marine-layer",
		description: "Dive into Marine Layer's zero-waste fashion, creating quality custom gear without compromising the environment. Take a look at their latest swag.",
		image: require('../../../images/blog/marine-layer.jpg'),
		content: ""
	},
	{
		title: "Brand We Love: S'Well",
		date: new Date("3/1/22"),
		slug: "swell",
		description: "Elevate your custom swag sustainably with S'well, a brand that merges fashion and eco-consciousness. Find out why we love them at Swagger.",
		image: require('../../../images/blog/swell/branded-swell-bottle.jpg'),
		content: ""
	},
	{
		title: "Back-to-the-Office Employee Swag",
		date: new Date("2/1/22"),
		slug: "back-to-office-gifts",
		description: "Looking for a special way to welcome back employees? Check out our back-to-the-office employee swag! Feel good gifting eco-friendly swag that will spark team connections.",
		image: require('../../../images/blog/back-to-office-swag.jpg'),
		content: ""
	},
	{
		title: "Brand We Love: MiiR",
		date: new Date("9/1/21"),
		slug: "miir",
		description: "Explore MiiR: Elevate your company's swag game with MiiR's bold and stylish. Discover why MiiR is the go-to brand for eco-conscious companies looking to make a statement. Read on to find out why they’re one of Swagger’s top picks.",
		image: require('../../../images/blog/miir/miir1.png'),
		content: ""
	},
	{
		title: "Brand We Love: Timbuk2",
		date: new Date("8/1/21"),
		slug: "timbuk2",
		description: "Elevate your company branded swag with Timbuk2. Read more about their lifetime guarantee and how they’re not your typical messenger bag.",
		image: require('../../../images/blog/timbuk2/timbuk2-1.jpg'),
		content: ""
	},
	{
		title: "Holiday Lookbook 2022",
		date: new Date("10/1/22"),
		slug: "holiday2022",
		description: "Thoughtfully curated employee and customer holiday gifts.",
		image: require('../../../images/blog/holiday-lookbook-2022.jpg'),
		content: ""
	},
	{
		title: "Brand We Love: The North Face",
		date: new Date("11/1/22"),
		slug: "the-north-face",
		description: "Explore custom North Face apparel at Swagger—sustainable, quality corporate swag that inspire and give back. Embrace custom swag with style.",
		image: require('../../../images/blog/north-face/corporate-gifts-The-North-Face-backpack.png'),
		content: ""
	},
	{
		title: "Our Top 9 Favorite Sustainable Bags",
		date: new Date("12/1/22"),
		slug: "our-top-9-sustainable-bags",
		description: "Looking for sustainable bags that make the best corporate branded swag gifts? From backpacks to totes to duffels to crossbody and more...check out our favorites. Here are the top eight sustainable bags we love.",
		image: require('../../../images/blog/sustainable-bags/herschel-duffel.png'),
		content: ""
	},
	{
		title: "2023 Trends",
		date: new Date("1/1/23"),
		slug: "2023trends",
		description: "Swagger’s take on 2023 Top Trends in Swag.",
		image: require('../../../images/blog/2023-trends.png'),
		content: ""
	},
	{
		title: "Brand We Love: Karst",
		date: new Date("2/1/23"),
		slug: "karst",
		description: "Immerse yourself in sustainable style with Swagger's showcase of Karst notebooks. Read on about embracing green design on every page.",
		image: require('../../../images/blog/karst/karst.png'),
		content: ""
	},
	{
		title: "Brand We Love: Econscious",
		date: new Date("6/1/23"),
		slug: "econscious",
		description: "Explore how Econscious is transforming the world of sustainable branded swag with stylish, eco-friendly products. Learn why they're a top pick for eco-conscious companies.",
		image: require('../../../images/blog/econscious/eco1.png'),
		content: ""
	},
	{
		title: "10 Ways to Go Plastic-free With Your Swag",
		date: new Date("7/1/23"),
		slug: "plastic-free-july",
		description: "In support of Plastic Free July, we've rounded up our favorite 10 plastic-free, eco-friendly swag items that prove you can be kind to the planet without compromising on quality and style.",
		image: require('../../../images/blog/plastic-free/tote-1.png'),
		content: ""
	},
	{
		title: "Brand We Love: Fellow",
		date: new Date("8/1/23"),
		slug: "fellow-coffee-products",
		description: "Uncover Fellow's commitment to redefining the coffee experience with environmentally conscious and groundbreaking products. Read more about why Swagger thinks Fellow's coffee gear is the perfect addition to any company branded swag collection. ",
		image: require('../../../images/blog/fellowcoffee/fellow1.png'),
		content: ""
	},
	{
		title: "Brand We Love: Modern Sprout",
		date: new Date("9/1/23"),
		slug: "modern-sprout",
		description: "Discover Modern Sprout: an eco-conscious brand revolutionizing urban gardening with sustainable, original products. Find out why it’s perfect for green branded swag!",
		image: require('../../../images/blog/modernsprout/ms1.png'),
		content: ""
	},
	{
		title: "Brand We Love: Alternative Apparel",
		date: new Date("10/1/23"),
		slug: "alternative-apparel",
		description: "Discover Alternative Apparel, a brand that combines eco-conscious fashion with comfort. See why it's a top choice for sustainable and stylish company swag at Swagger.",
		image: require('../../../images/blog/alternative-apparel/alt1.png'),
		content: ""
	},
	{
		title: "Our 2023 Impact",
		date: new Date("1/1/24"),
		slug: "2023-impact",
		description: "Excited to share our 2023 impact as we welcome 2024. Check out the highlights from Swagger’s sustainability efforts & gratitude for our clients.",
		image: require('../../../images/blog/2023-impact/2023-impact-cover.png'),
		content: ""
	},
	{
		title: "Brand We Love: Nimble",
		date: new Date("3/1/23"),
		slug: "nimble",
		description: "Explore eco-friendly Nimble Portable Chargers—a fusion of sustainable tech and custom swag. Upgrade your branded merchandise and company swag to Nimble with Swagger!",
		image: require('../../../images/blog/nimble/nimble-1.webp'),
		content: '',
	},
	{
		title: "Brand We Love: Nike",
		date: new Date("5/1/23"),
		slug: "nike",
		description: "Tap into Nike’s performance-driven company swag. Read now to find out why Swagger’s obsessed with Nike’s giveback and how to support the brand.",
		image: require('../../../images/blog/nike/nike1.png'),
		content: '',
	},
	{
		title: "Brand We Love: Carhartt",
		date: new Date("4/1/23"),
		slug: "carhartt",
		description: "Dive into the sustainable and durable world of Carhartt for eco-friendly, high-quality branded swag that stands the test of time. Read on to explore why Swagger loves Carhartt.",
		image: require('../../../images/blog/carhartt/carhartt1.png'),
		content: '',
	},
	{
		title: "Brand We Love: Herschel",
		date: new Date("11/1/23"),
		slug: "herschel",
		description: "Explore Herschel's range of stylish and durable bags and accessories. Check out their company swag options and learn more about their commitment to quality.",
		image: require('../../../images/blog/herschel/herschel1.png'),
		content: '',
	},
	{
		title: "Brand We Love: Cotopaxi",
		date: new Date("12/1/23"),
		slug: "cotopaxi",
		description: "Discover Cotopaxi's vibrant and sustainable backpacks and hip packs. Explore their stylish company swag options and learn about their commitment to sustainability.",
		image: require('../../../images/blog/cotopaxi/cotopaxi1.png'),
		content: '',
	},
	{
		title: "2024 Trends",
		date: new Date("1/30/24"),
		slug: "2024-trends",
		description: "Ready to shake things up this year? Check out Swagger's favorite 2024's Corporate Gifting Trends! Here's the top 7 trends for your company branded swag.",
		image: require('../../../images/blog/2024-trends/2024-trends.png'),
		content: ""
	},
	{
		title: "Swagger’s Favorite BIPOC Businesses You Should Know",
		date: new Date("2/1/24"),
		slug: "bipocbrands",
		description: "Dive into Swagger’s favorite BIPOC businesses you should know about. These businesses are stirring up the corporate branded swag world and leaving their mark.",
		image: require('../../../images/blog/bipocbrands/bipoc1.jpg'),
		content: ""
	},
	{
		title: "Celebrating Our Top 9 Women-Owned, Custom Swag Brands",
		date: new Date("3/8/24"),
		slug: "women-owned-businesses",
		description: "As Swagger celebrates International Women's Day, we're thrilled to share our top 9 Women-Owned brands that elevate your branded swag.",
		image: require('../../../images/blog/womenowned/wo1.png'),
		content: ""
	},
	{
		title: "Eco-Friendly Company Swag: Swagger’s Fave Roundup of BCorp Brands",
		date: new Date("3/1/24"),
		slug: "bcorp-brand-roundup",
		description: "In support of BCorp month in March, we've rounded up our top 11 favorite BCorp brands. Read more about eco-friendly company swag!",
		image: require('../../../images/blog/bcorp/bcorp1.png'),
		content: ""
	},
	{
		title: "Brand We Love: Moleskine",
		date: new Date("10/15/23"),
		slug: "moleskine",
		description: "Explore Moleskine's iconic and sustainable products. Dive into their top-quality branded merchandise and learn about their commitment to the environment.",
		image: require('../../../images/blog/moleskine/moleskine1.png'),
	},
	{
		title: "Brand We Love: YETI",
		date: new Date("11/15/23"),
		slug: "yeti",
		description: "Discover YETI 's range of durable, insulated water bottles for your corporate branded swag needs. Find high-quality, eco-friendly options with Swagger.",
		image: require('../../../images/blog/yeti/yeti1.png'),
	},
	{
		title: "Brand We Love: Tentree",
		date: new Date("1/15/24"),
		slug: "tentree",
		description: "Explore Tentree's sustainable and fashionable apparel. Dive into their top-notch branded merch and learn about their commitment to the environment.",
		image: require('../../../images/blog/tentree/tentree1.png'),
	},
	{
		title: "Brand We Love: Day Owl",
		date: new Date("12/15/23"),
		slug: "day-owl",
		description: "Uncover the world of Day Owl's sustainable bags. Learn about their commitment to eco-friendly practices and how they can enhance your branded merchandise.",
		image: require('../../../images/blog/day-owl/day-owl1.jpg'),
	},
	{
		title: "Brand We Love: Original Favorites",
		date: new Date("9/15/23"),
		slug: "original-favorites",
		description: "Discover Original Favorites' premium and ethically-made apparel. Explore their stylish company swag options and learn about their commitment to ethical manufacturing.",
		image: require('../../../images/blog/original/original1.png'),
	},
	{
		title: "Brand We Love: Urbanista Headphones",
		date: new Date("8/15/23"),
		slug: "urbanista-headphones",
		description: "Explore Urbanista: a trendsetting brand in audio tech, offering sleek, innovative products. Learn why it's the perfect choice for company branded swag that hits the right note.",
		image: require('../../../images/blog/urbanista/urbanista1.png'),
	},
	{
		title: "Brand We Love: Osprey",
		date: new Date("9/30/23"),
		slug: "osprey",
		description: "Dive into Osprey's sustainable and stylish outdoor gear. Learn about their top-notch branded merchandise and commitment to the environment.",
		image: require('../../../images/blog/osprey/osprey1.png'),
	},
	{
		title: "Brand We Love: Topo Designs",
		date: new Date("4/1/24"),
		slug: "topo-designs",
		description: "Explore Topo Designs: a brand that stands at the intersection of outdoor durability and urban flair. Learn why their products are the perfect addition to your company branded swag.",
		image: require('../../../images/blog/topo/topo1.png'),
	},
	{
		title: "Impact Through Action: Swagger's Commitment to Donating 1% for the Planet",
		date: new Date("4/22/24"),
		slug: "1-percent-for-the-planet",
		description: "Read more about Swagger's Impact. Dive into our partnership with 1% for the Planet, where custom swag meets environmental stewardship. Uncover how every action contributes to a sustainable future.",
		image: require('../../../images/blog/1-percent/1-percent1.png'),
	},
	{
		title: "Brand We Love: TravisMathew",
		date: new Date("7/15/23"),
		slug: "travismathew",
		description: "Explore TravisMathew: a leader in versatile fashion, offering stylish, high-performance attire perfect for the golf course and beyond. Discover why it's the top choice for company branded swag that makes a statement everywhere.",
		image: require('../../../images/blog/travismathew/travismathew1.jpg'),
	},
	{
		title: "Brand We Love: Patagonia",
		date: new Date("2/15/24"),
		slug: "patagonia",
		description: "Discover Patagonia's sustainable apparel and gear for your corporate branded swag needs. Shop with Swagger for eco-conscious, quality products.",
		image: require('../../../images/blog/patagonia/patagonia1.png'),
	},
	{
		title: "Brand We Love: Hydro Flask",
		date: new Date("3/15/23"),
		slug: "hydroflask",
		description: "Get to know Hydro Flask's robust and stylish bottles. See their commitment to sustainability and pick the best eco-friendly corporate branded swag for your team.",
		image: require('../../../images/blog/hydroflask/hydroflask1.png'),
	},
	{
		title: "Brand We Love: Known Supply",
		date: new Date("4/30/24"),
		slug: "known-supply",
		description: "Explore Known Supply's range of ethically made and customizable clothing. Learn about their fair-trade practices and see how your company swag can make a difference.",
		image: require('../../../images/blog/known-supply/known-supply1.png'),
	},
	{
		title: "Celebrating AAPI Heritage Month with Swagger’s Favorite AAPI-Owned Brands",
		date: new Date("5/1/24"),
		slug: "AAPI-faves",
		description: "Step into the exciting realm of AAPI-owned businesses with Swagger this AAPI Heritage Month! We're spotlighting brands that bring a fresh spin to branded merch, from stylish eco-apparel to sleek, modern stationery. Let's explore these dynamic brands and see how they're enriching the corporate swag scene with their unique stories and standout products.",
		image: require('../../../images/blog/aapi/aapi1.png'),
	},
	{
		title: "USA-MADE Company Swag: Swagger’s Fave Roundup of USA Made Brands",
		date: new Date("7/26/24"),
		slug: "usa-made-swag",
		description: "In support of the Olympics this summer, we've rounded up our top USA-Made brands. Read more about USA-Made company swag!",
		image: require('../../../images/blog/usa-swag/usa-swag1.png'),
	},
	{
		title: "Swagger's Guide to Hispanic Heritage Month: Featured Brands",
		date: new Date("9/1/24"),
		slug: "swaggers-guide-to-hispanic-heritage-month-featured-brands",
		description: "Explore Swagger's featured brands for Hispanic Heritage Month, celebrating diverse and sustainable products from Hispanic and Latino-owned businesses.",
		image: require('../../../images/blog/hispanic-heritage-month/heritage1.png'),
	}
]